var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "review-bg" },
    [
      _c("Navbar"),
      _c(
        "div",
        { staticClass: "review-body" },
        [
          _c("TopBar"),
          _c(
            "div",
            { staticClass: "filter-bar boxarea" },
            [_c("SelectLayer")],
            1
          ),
          _c("section", { staticClass: "review-insight1" }, [
            _c(
              "div",
              { staticClass: "boxarea" },
              [
                _c("h2", [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/reviewmind/chart_02.png"),
                      alt: "아이콘"
                    }
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("level_satisfaction")) + " ")
                ]),
                _c("button", { on: { click: _vm.handleReviews } }, [
                  _vm.loading
                    ? _c("i", { staticClass: "el-icon-loading" })
                    : _c("i", { staticClass: "el-icon-document" })
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.comma(_vm.ratingTotalCount)) +
                      " " +
                      _vm._s(_vm.$t("reviews"))
                  )
                ]),
                _vm.sentiment_pred.length == 0
                  ? _c("div", { staticClass: "nodata" }, [
                      _vm._v(" " + _vm._s(_vm.$t("no_data")) + " ")
                    ])
                  : _c("Semi-Donut", {
                      attrs: {
                        series: _vm.sentiment_pred,
                        point: _vm.centerPoint
                      }
                    }),
                _c(
                  "div",
                  { staticClass: "confidence" },
                  [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("review_credibility")))]),
                    _c("el-progress", {
                      attrs: {
                        "text-inside": true,
                        "stroke-width": 20,
                        percentage: _vm.credibility
                      }
                    }),
                    _c("p", [_vm._v(_vm._s(_vm.getScore(_vm.credibility)))])
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "boxarea" }, [
              _c("div", { staticClass: "rate-trend" }, [
                _c("h2", [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/reviewmind/chart_02.png"),
                      alt: "아이콘"
                    }
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("Reviews_StarTrends")) + " ")
                ]),
                _c("div", { staticClass: "page2-filter" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isCredibility,
                          expression: "isCredibility"
                        }
                      ],
                      attrs: { name: "" },
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.isCredibility = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.handleCredibility
                        ]
                      }
                    },
                    [
                      _c("option", { attrs: { value: "all", selected: "" } }, [
                        _vm._v(" " + _vm._s(_vm.$t("review_credibility")) + " ")
                      ]),
                      _c("option", { domProps: { value: true } }, [
                        _vm._v(_vm._s(_vm.$t("credible")))
                      ]),
                      _c("option", { domProps: { value: false } }, [
                        _vm._v(_vm._s(_vm.$t("non_credible")))
                      ])
                    ]
                  ),
                  _c("select", { attrs: { name: "" } }, [
                    _c("option", { attrs: { value: "", selected: "" } }, [
                      _vm._v(_vm._s(_vm.$t("review_source")))
                    ]),
                    _c("option", { attrs: { value: "" } }, [_vm._v("amazon")]),
                    _c("option", { attrs: { value: "" } })
                  ])
                ])
              ]),
              _c(
                "div",
                { staticClass: "chartBox" },
                [
                  _c("VueApexCharts", {
                    ref: "chart",
                    class: { fullSize: _vm.index == 1 },
                    attrs: {
                      type: "line",
                      height: "260",
                      options: _vm.chartOptions,
                      series: _vm.series
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("section", { staticClass: "review-insight2" }, [
            _c(
              "div",
              { staticClass: "boxarea r-insight-chart" },
              [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.productList[0].type == "naver" ? "Naver" : "Amazon"
                    )
                  )
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.comma(_vm.ratingTotalCount)) +
                      " " +
                      _vm._s(_vm.$t("reviews"))
                  )
                ]),
                _vm.insightSentimentList.length == 0
                  ? _c("div", { staticClass: "nodata" }, [
                      _vm._v(" " + _vm._s(_vm.$t("no_data")) + " ")
                    ])
                  : _c("Simple-Donut", {
                      attrs: { series: _vm.competitorA, point: _vm.donutCenter }
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "boxarea r-insight-chart" },
              [
                _c("p", [_vm._v("Best Buy")]),
                _c("span", [_vm._v("0 " + _vm._s(_vm.$t("reviews")))]),
                _vm.competitorB.length == 0
                  ? _c("div", { staticClass: "nodata" }, [
                      _vm._v(" " + _vm._s(_vm.$t("no_data")) + " ")
                    ])
                  : _c("Simple-Donut", { attrs: { series: _vm.competitorB } })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "boxarea r-insight-chart" },
              [
                _c("p", [_vm._v("Wall Mart")]),
                _c("span", [_vm._v("0 " + _vm._s(_vm.$t("reviews")))]),
                _vm.competitorC.length == 0
                  ? _c("div", { staticClass: "nodata" }, [
                      _vm._v(" " + _vm._s(_vm.$t("no_data")) + " ")
                    ])
                  : _c("Simple-Donut", { attrs: { series: _vm.competitorC } })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "boxarea r-insight-chart" },
              [
                _c("p", [_vm._v("Costco")]),
                _c("span", [_vm._v("0 " + _vm._s(_vm.$t("reviews")))]),
                _vm.competitorD.length == 0
                  ? _c("div", { staticClass: "nodata" }, [
                      _vm._v(" " + _vm._s(_vm.$t("no_data")) + " ")
                    ])
                  : _c("Simple-Donut", { attrs: { series: _vm.competitorD } })
              ],
              1
            )
          ]),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.modalVisible
              ? _c(
                  "div",
                  { staticClass: "dim" },
                  [
                    _c("ReviewDetail2", {
                      attrs: {
                        reviewList: _vm.reviewList,
                        name: _vm.productName
                      },
                      on: { close: _vm.close }
                    }),
                    _c("div", {
                      staticClass: "dimbg",
                      on: { click: _vm.close }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }